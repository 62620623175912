import { graphql } from 'gatsby';
import React from 'react';
import SingleTemplate from './single';
import { getIndexPageMeta } from '../utils/helpers';
import { PAGE_TYPE } from '../utils/constants';
import DefaultProxy from '../components/proxy/blocks';

const RegularTemplate = props => {
  const { site, markdownRemark } = props.data;

  const seo = getIndexPageMeta({
    pathname: props.location.pathname,
    siteMetadata: site.siteMetadata,
    frontmatter: markdownRemark.frontmatter,
  });

  return (
    <SingleTemplate
      seo={seo}
      pageType={PAGE_TYPE.Default}
      proxy={DefaultProxy}
      {...props}
    />
  );
};

export default RegularTemplate;

export const regularPageQuery = graphql`
  query RegularPageQuery($slug: String) {
    site {
      siteMetadata {
        siteName
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      ...PageMarkdownFragment
    }
    graphData: sidebarJson {
      ...ProvidersFragment
      ...ContinentsFragment
      ...ContinentEntriesFragment
      ...WorldwideEntriesFragment
    }
    ...ConfigFragment
  }
`;

export const configFragment = graphql`
  fragment ConfigFragment on Query {
    config: configJson(_template: { eq: "site_config" }) {
      global_meta_tags {
        name
        content
      }
      variables {
        id
        value
      }
      inline_scripts {
        raw_html
        disabled
      }
      external_scripts {
        src
        load_strategy
        disabled
      }
      top_banner {
        enabled
        text
        cta_text
        cta_url
      }
      hiring_banner {
        enabled
        image
        title
        subtitle
        url
        image_alt_text
      }
      fluffee_blacklist
    }
  }
`;

export const ImageFieldFragment = graphql`
  fragment ImageFieldFragment on ImageField {
    _template
    src
    alt
    rive_autoplay
    rive_image
    rive_state_machine_name
    offset {
      horizontal
      vertical
    }
    scale {
      x
      y
    }
  }
`;

export const SettingsFragment = graphql`
  fragment SettingsFragment on Settings {
    css_class_name
    curved_bottom
    custom_css
    negative_margin
    padding_top
    padding_bottom
    margin_top
    margin_bottom
  }
`;

export const PageMarkdownFragment = graphql`
  fragment PageMarkdownFragment on MarkdownRemark {
    id
    excerpt
    html
    fileAbsolutePath
    fields {
      slug
    }
    frontmatter {
      menu_src_override
      url_alias
      title
      hides {
        footer_top
        footer_bottom
        header_menu_items
        header_menu
      }
      meta {
        description
        keywords
        og_image
      }
      parent_page
      sections {
        section_margin
        background_width
        background_color
        background_image
        background_illustration
        reverse_gradient
        settings {
          ...SettingsFragment
        }
        blocks {
          offset {
            horizontal
            vertical
          }
          scale {
            x
            y
          }
          _template
          settings {
            ...SettingsFragment
          }
          left {
            image
            image_alt_text
          }
          right {
            title
            text
            logo
          }
          ...PricingFragment
          block_visibility
          thank_you_page
          campaign_type
          dates
          people
          submit_url
          title_id
          form {
            _template
            campaign
            settings {
              ...SettingsFragment
            }
            side_image
            side_image_alt
            submit_label
            support_text
          }
          hide_image_on_mobile
          center_text_on_mobile
          title
          title_mobile
          title_size {
            mobile
            desktop
          }
          text_type
          pre_title
          subtitle
          subtitle_mobile
          intro_text
          text
          html
          small_text
          image
          image_alt_text
          image_position
          image_max_width
          default_storage_amount
          default_storage_unit
          default_disk_type
          show_disk_type_toggle
          default_regions
          align
          show_bunnies_image
          add_form_margin
          region
          region_label
          text_width
          form_style
          side_cta {
            title
            url
          }
          submenu_field {
            menu_group_title
            menu_items {
              menu_title
              menu_url
              menu_class_name
              submenu_level_1 {
                submenu_title
                submenu_url
              }
            }
          }
          mobile {
            text
          }
          cta_field {
            cta_text
            cta_type
            cta_url
            cta_position
            cta_subtext
            override_behaviour
            subtext_right
            style
            second_cta {
              text
              url
              color
            }
            video_cta {
              text
              html
            }
            mobile {
              text
            }
          }
          cta_contact {
            cta_text
            cta_url
            cta_position
            cta_subtext
            subtext_right
            style
            second_cta {
              text
              url
              color
            }
          }
          cta_text
          cta_url
          cta_position
          cta_subtext
          position
          background_color
          squares {
            title
            text
            icon
          }
          group {
            label
            squares {
              title
              text
              icon
            }
          }
          features {
            title
            text
            icon
            style
          }
          theme
          tab_position
          header_col
          data_col
          product_list {
            title
            text
            url
            icon
            tag {
              text
              color
            }
          }
          columns {
            value
            text
            icon
            logo
          }
          reviews {
            ...ReviewFragment
          }
          text_position
          testimonials {
            text
            image_logo
            image_person
            quote
            name
            company
            image
            image_alt_text
          }
          items {
            title
            subtitle
            icon
            text
            name
            position
            image
            image_alt_text
            question
            answer
            timeline_title
            date
            price
            performance
            url
            cta_field {
              cta_text
              cta_url
              cta_position
              cta_subtext
              style
            }
            tab_title
            tab_title_mobile
            blocks {
              _template
              settings {
                ...SettingsFragment
              }
              title
              title_mobile
              text
              subtitle
              footer_text
              side_text
              pricing {
                title
                price
              }
              cta_text
              cta_url
              cta_position
              cta_subtext
              subtext_right
              style
              second_cta {
                text
                url
                color
              }
              mobile {
                text
              }
              image_position
              image_alt_text
              image
              cta_field {
                cta_text
                cta_url
                cta_position
                cta_subtext
                style
              }
              small_text
              align
              style
            }
          }
          rows_no
          show_numbers
          title_id
          submit_label
          support_text
          accordion {
            title
            title_id
            title_icon
            text
            cta_field {
              cta_text
              cta_url
              cta_position
              cta_subtext
              style
            }
          }
          style
          list_size
          html
          head_columns {
            highlighted
            title
            image
            text
          }
          rows {
            text
            title
            subtitle
            columns {
              text
              icon
              title
              subtitle
              bold
              rating {
                current_rating
                max_rating
                number_of_stars
              }
            }
          }
          left {
            pre_title
            title
            subtitle
            text
            image_alt_text
            image
            icon
          }
          right {
            pre_title
            title
            subtitle
            text
            image_alt_text
            image
          }
          right_top {
            title
            text
            icon
          }
          right_bottom {
            title
            text
            icon
          }
          legend_visibility
          footer_text
          side_text
          pricing {
            title
            price
          }
          container_width
          text_with_blocks {
            blocks {
              _template
              title
              text
              icon
              cols
              list_columns
              submit_label
              items {
                title
                subtitle
                text
                icon_position
                icon
                url
              }
              head_columns {
                text
              }
              rows {
                text
                columns {
                  text
                }
              }
              cta_position
              cta_text
              cta_url
              cta_subtext
              style
            }
          }
          additional_items {
            ...AdditionalItemsFragment
          }
          companies_list {
            ...CompanyFragment
          }
          case_study_grids {
            type
            case_studies {
              ...CaseStudyTileFragment
            }
          }
          image_width {
            desktop
            mobile
          }
          rating {
            current_rating
            max_rating
            number_of_stars
          }
          ...CaseStudyPanelFragment
          ...LabeledRatingFragment
          ...MultiformFragment
          ...SimpleBannerFragment
          ...NewsletterFragment
          ...RiveFragment
          ...DiplomaFragment
          ...PlatformCardsFragment
          ...CareerFragment
          ...BlogHeroFragment
          ...ListFragment
          ...DisqusFragment
          ...GraphFragment
          ...AcademyMenuFragment
          ...HelpfulFragment
          ...ColumnsFragment
          ...CarouselLogoFragment
          ...CheckmarksFragment
          ...VideoBlockFragment
          ...VideoStreamBlockFragment
          ...IntroductionFragment
          ...StrengthsFragment
          ...VideoEmbedBlockFragment
          ...TabsBlockFragment
          ...BTabsBlockFragment
          ...UseCasesFragment
          ...LargeCardItemsFragment
          blocks {
            ...NestedRegularBlockFragment
            ...NestedColumnsBlockFragment
          }
          column_select
          show_index
          block_state
          type
          show_buttons
          show_slider
          hide_filter
          slider_theme
          dividing_space {
            unit
            desktop
            mobile
          }
        }
      }
    }
  }
`;

export const nestedRegularBlockFragment = graphql`
  fragment NestedRegularBlockFragment on NestedBlock {
    ... on RegularNestedFields {
      settings {
        ...SettingsFragment
      }
      scale_on_hover
      reviews {
        ...ReviewFragment
      }
      hide_on_mobile
      platforms
      justify_content
      align_items
    }
  }
`;
